.place-filter {
    margin-bottom: 20px;
  }
  
  .toggle-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .filter-content {
    display: flex;
    margin-top: 10px;
  }
  
  .filter-content input {
    flex: 1;
    margin-right: 10px;
  }
  
  .filter-content button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
