.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .not-found-content {
    text-align: center;
  }
  
  .not-found-title {
    font-size: 3rem;
    color: #333;
  }
  
  .not-found-message {
    font-size: 1.5rem;
    color: #666;
  }
  
  .not-found-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .not-found-link:hover {
    background-color: #0056b3;
  }
  